import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Styled } from '../styles/legal.styles'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'

const PoliticaPrivacidadePage = () => {
  const { language } = useI18next()

  return (
    <Layout isHome>
      <SEO
        title="Política de Privacidade"
        description="Política de Privacidade da Next2U."
      />
      <Styled.Main>
        {language === 'en' ? (
          <>
            <h1>Privacy Policy</h1>

            <div className="content">
              <p>
                Dinastia, a Real Estate Company and Hotelier, LDA, with
                headquarters in Rua Direita de Aradas, 498, 3810 004 Aveiro,
                with a registered capital of 156,500 (one hundred and fifty-six
                thousand five hundred euros), registered in the Commercial
                Registry of Aveiro, registration number and corporate entity
                514070803.
              </p>

              <p>
                Dinastia, a Real Estate Company and Hotelier, LDA, hereinafter
                referred to as Dynasty, is responsible for the website
                next2u.pt, hereinafter referred to as Next2u and undertakes the
                commitment to guarantee the privacy of personal data collected
                and/or transmitted online. The security and privacy of Next2u
                site users' data are aspects that assume crucial importance for
                the company. Please read the following text to understand and
                learn how the information you provide will be handled by Next2u.
                Next2u reserves the right to change the privacy policy, so it is
                advisable to read this document regularly. These texts
                faithfully reproduce the privacy policy followed by the site
                next2u.pt. The following questions can be found in this
                document:
              </p>

              <ol>
                <li>What information is collected about users?</li>
                <li>Who is responsible for collecting the information?</li>
                <li>How is the information collected used?</li>
                <li>
                  How can you correct or delete the information you previously
                  provided through next2u.pt?
                </li>
                <li>
                  Other questions you should be aware of about next2u.pt’s
                  privacy policy.
                </li>
              </ol>

              <p>
                If you have any questions about some of the points presented
                here, please feel free to contact us by e-mail to
                Dinastiabusiness@gmail.com or by mail to Dinastia, Rua Direita
                de Aradas, 498, 3810 004 Aveiro.
              </p>

              <p>
                1. What information are we collecting about our users? To become
                a subscriber of our newsletter through our website it is
                necessary to complete the form that is available online on the
                site next2u.pt. The collection of personal data is carried out
                when the user completes the form. The information collected is
                necessary to ensure the identity of the user. The provision of
                the requested data in the act of registration of the website of
                next2u.pt is mandatory that the user can enjoy the
                above-mentioned service. In the act of registration, the
                information that the user makes available aims to provide a more
                appropriate service аs its characteristics and needs and to send
                information related to the services provided by Next2u, such as
                campaigns, events, or general information. The reader will be
                able to update the information provided by you whenever they
                want by simply sending the request to dynastiureness@gmail.com.
                The provision of personal data is adequately protected. All
                information is encrypted and managed with the most advanced
                security tools.
              </p>

              <p>
                2. Which entity is responsible for collecting the information?
                Next2u is solely responsible for the collection of personal data
                performed on the next2u.pt website. Whenever the user finishes
                the use of this service, Next2u assumes no responsibility for
                the information collected outside the respective website.
              </p>

              <p>
                3. How is the information collected used? The information
                collected will only serve the following purposes: to send a
                message via the contact form; to send a message via the
                recruitment form. The personal data provided by the user will be
                used exclusively for linked purposes а performance of the
                respective contract, as well as, if authorized by the user, for
                information, marketing, and telemarketing activities of Next2u.
                Communications of data shall be made with the prior consent of
                the data subjects. Next2u.pt will not sell, rent, share or offer
                the data provided by its readers, except in the situations
                provided in this privacy policy.
              </p>

              <p>
                4. How can you correct or delete the information you previously
                provided in Next2u applications? Under the conditions of use of
                the site next2u.pt and all its services, the User undertakes not
                to save any data, we only send them by email.
              </p>

              <p>
                5. Other questions you should be aware of about the privacy
                policy of next2u.pt website:
                <br />
                - Changes to the terms of use of the next2u.pt website.
                <br />
                Whenever there is a change in the conditions of use of the site
                next2u.pt, namely in the Terms of Service or Privacy Policy,
                Next2u will send an email to its users.
                <br />- Authorisation for Miscellaneous Uses
                <br />
                If Next2u intends to use its users' data for purposes other than
                those for which it received initial authorization from users, it
                will request a new authorization from users for that specific
                use. National Data Protection Commission The data subject may
                submit a complaint а supervisory authority (CNPD). However, if
                you wish to make such a complaint, do not hesitate to contact
                Next2u at the first stage, via the email
                dynastiabusiness@gmail.com.
              </p>
            </div>
          </>
        ) : (
          <>
            <h1>Política de Privacidade</h1>

            <div className="content">
              <p>
                Dinastia, Sociedade Imobiliária e Hoteleira, LDA, com sede na
                Rua Direita de Aradas, 498, 3810 004 Aveiro, com capital social
                de 156.500 (cento e cinquenta e seis mil e quinhentos euros),
                registada na Conservatória do Registo Comercial de Aveiro, com o
                número de matrícula e de pessoa coletiva 514070803.
              </p>

              <p>
                Dinastia, Sociedade Imobiliária e Hoteleira, LDA, adiante
                designada por Dinastia que é responsável pelo site next2u.pt,
                adiante designado por site Next2u, e compromete-se a garantir a
                privacidade dos dados pessoais recolhidos e/ou transmitidos
                online. A segurança e a privacidade dos dados dos utilizadores
                do site Next2u são aspetos que assumem para a empresa uma
                importância crucial. Por favor, leia o texto que se segue para
                compreender e tomar conhecimento da forma como as informações
                que disponibilizar serão tratadas pela Next2u. A Next2u
                reserva-se ao direito de alterar a política de privacidade, pelo
                que se aconselha a leitura regular deste documento. Estes textos
                reproduzem fielmente a política de privacidade seguida pelo site
                next2u.pt. No presente documento, poderá encontrar a resposta às
                seguintes questões:
              </p>
              <ol>
                <li>
                  Qual é a informação que é recolhida sobre os utilizadores?
                </li>
                <li>
                  Qual é a entidade responsável pela recolha da informação?
                </li>
                <li>Como é que a informação recolhida é utilizada?</li>
                <li>
                  De que forma pode o utilizador corrigir ou eliminar as
                  informações que facultou previamente através do site
                  next2u.pt?
                </li>
                <li>
                  Outras questões de que deverá ter conhecimento sobre a
                  política de privacidade da next2u.pt.
                </li>
              </ol>

              <p>
                Se tiver qualquer dúvida sobre alguns dos pontos aqui
                apresentados, agradecemos que nos comunique por e-mail para
                dinastiabusiness@gmail.com ou por correio para Dinastia, Rua
                Direita de Aradas, 498, 3810 004 Aveiro.
              </p>

              <p>
                1. Qual é a informação que é recolhida sobre os utilizadores?
                Para que se possa tornar um subscritor da nossa newsletter
                através do nosso site é necessário proceder ao preenchimento do
                formulário que se encontra disponível online no site next2u.pt.
                A recolha dos dados pessoais é efetuada no momento do
                preenchimento do formulário. A informação recolhida é a
                necessária para assegurar a identidade do utilizador. O
                fornecimento dos dados solicitados no ato de registo do site da
                next2u.pt é obrigatório que o utilizador possa usufruir do
                serviço supramencionado. No ato de registo, a informação que o
                utilizador disponibiliza tem por objetivo a prestação de um
                serviço mais adequado às suas características e necessidades e
                ao envio de informações relacionadas com os serviços prestados
                pela Next2u, tais como: campanhas, eventos ou informações
                gerais. O leitor poderá atualizar as informações por si
                fornecidas sempre que quiser bastando que para esse efeito envie
                o pedido para dinastiabusiness@gmail.com. As operações de
                fornecimento de dados pessoais estão devidamente protegidas.
                Toda a informação é encriptada e gerida com as mais avançadas
                ferramentas de segurança.
              </p>

              <p>
                2. Qual é a entidade responsável pela recolha da informação? A
                Next2u é a única responsável pela recolha de dados pessoais
                efetuada no site next2u.pt. Sempre que o utilizador termine a
                utilização deste serviço, a Next2u não assume qualquer
                responsabilidade pela informação recolhida fora da respetiva
                página eletrónica.
              </p>

              <p>
                3. Como é que a informação recolhida é utilizada? A informação
                recolhida servirá apenas para os seguintes propósitos: enviar
                uma mensagem através do formulário de contacto; enviar uma
                mensagem através do formulário de recrutamento. Os dados
                pessoais fornecidos pelo utilizador serão utilizados
                exclusivamente para fins ligados à execução do respetivo
                contrato, bem como, se o utilizador assim o autorizar, para
                atividades de informação, marketing e telemarketing da Next2u.
                As comunicações de dados serão feitas mediante prévio
                consentimento dos titulares dos dados. A Next2u.pt não procederá
                à venda, aluguer, partilha ou oferta dos dados fornecidos pelos
                seus leitores, exceto nas situações previstas na presente
                política de privacidade.
              </p>

              <p>
                4. De que forma pode o utilizador corrigir ou eliminar as
                informações que facultou previamente nas aplicações da Next2u?
                No âmbito das condições de utilização do site next2u.pt e de
                todos os seus serviços, o utilizador compromete-se a não guardar
                nenhuns dados, apenas os enviamos por um e-mail.
              </p>

              <p>
                5. Outras questões de que deverá ter conhecimento sobre a
                política de privacidade do website next2u.pt:
                <br />
                - Alteração de Condições de Utilização o do site da
                next2u.ptSempre que houver uma alteração das condições de
                utilização do site next2u.pt, designadamente nas Condições de
                Serviço ou na Política de Privacidade, a Next2u enviará um email
                aos seus utilizadores.
                <br />- Autorização para Utilizações DiversasSe a Next2u
                pretender utilizar os dados dos seus utilizadores para
                finalidades distintas daquelas para as quais recebeu autorização
                inicial dos utilizadores, solicitará uma nova autorização dos
                utilizadores para essa utilização específica. Comissão Nacional
                de Proteção de Dados O titular dos dados poderá apresentar uma
                reclamação à autoridade de controlo (CNPD). Contudo, caso
                pretenda proceder a essa reclamação, não hesite em contactar a
                Next2u numa primeira fase, através do e-mail
                dinastiabusiness@gmail.com.
              </p>
            </div>
          </>
        )}
      </Styled.Main>
    </Layout>
  )
}

export default PoliticaPrivacidadePage

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["global"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
